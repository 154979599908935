<template>
    <div class="full-height">
        <img src="@/img/icon.png" class="centered-image"  alt="Logo Sinupai">
    </div>
</template>

<script>
export default {
    name: "main.vue"
}
</script>

<style scoped>
 .full-height{
      height: 100vh; /* Set the element's height to 100% of the viewport height */
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
 }

 .centered-image {
     max-height: 250px;
     max-width: 100%;
 }
</style>
